import React, { useContext, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Slide from "@mui/material/Slide";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import FolderIcon from "@mui/icons-material/Folder";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Stack, Icon, PrimaryButton, Text, Panel, PanelType, DefaultButton } from "office-ui-fabric-react";
import { TextField, Modal, Image } from "@fluentui/react";
import { Apps24Regular, SaveArrowRight24Regular, Play24Regular, List24Filled } from "@fluentui/react-icons";
import github from "../../components/pics/github.png";
import OneDrive from "../../components/pics/OneDrive.png";
import Avatar from "@mui/material/Avatar";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import { GlobalContext } from "../../../context/global";
import Switch from "@mui/material/Switch";
import mermaid from "mermaid";
import Viz from "viz.js";
import { Module, render } from "viz.js/full.render.js";
import { SignedIn, UserButton, SignedOut, SignInButton } from "@clerk/clerk-react";
import SaveModal from "../../modalPopup/SaveModal";
import microSoftImage from "../../components/pics/microsoft-logo.png";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

// Header component definition
const Header = ({
  insertDiagramAsImage, // Function to insert a diagram as an image
  OneDriveLocalData, // Local data for OneDrive integration
  setOneDriveFiles, // Function to set OneDrive files state
  oneDriveFiles, // State containing OneDrive files
  setGithubShareModal, // Function to set GitHub share modal state
  handleClose, // Function to handle closure (truncated, likely to be completed later)
  activeButton, // Function to handle Active Button state
  handleToggleChange, // Function to handle toggle state
}) => {
  // Destructuring properties from GlobalContext using useContext
  const {
    setGalleryModal, // Function to set gallery modal state
    showPopup, // State indicating if the popup is open
    setShowPopup, // Function to set popup state
    setInputValue, // Function to set input value
    setGraphvizCode, // Function to set graphviz code
    setMarkdown, // Function to set mermaid code
    markdown, // State containing mermaid code
    graphvizCode, // State containing graphviz code
    isSwitchChecked, // State indicating if the switch is checked
  } = useContext(GlobalContext); // Using the GlobalContext to access global state and functions

  // State variables
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileDetails, setFileDetails] = useState([]);
  const [tabBarValue, setTabBarValue] = React.useState(1);
  const [showMessageBarGithubGist, setShowMessageBarGithubGist] = useState(false);
  const [userToken, setUserToken] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [oneDriveUserToken, setOneDriveUserToken] = useState("");
  const [showOneDrivePopup, setShowOneDrivePopup] = useState(false);
  const [showGithubPopup, setShowGithubPopup] = useState(false);
  const [value, setValue] = useState(
    "https://github.com/login/oauth/authorize?client_id=4adade2a7a76ad7c2c9d&scope=gist"
  );
  const [oneDriveUserData, setOneDriveUserData] = useState("");
  const [getUserFileNameToOneDrive, setGetUserFileNameToOneDrive] = useState("");
  const [getUserFileNameToOneDriveSvg, setGetUserFileNameToOneDriveSvg] = useState("");
  // const [showPopup, setShowPopup] = useState(false);
  const [showPopup1, setShowPopup1] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);
  const [showPopup3, setShowPopup3] = useState(true);
  const [oneDriveId, setOneDriveId] = useState("");
  const [noLoginState, setNoLoginState] = useState(false);
  const [error, setError] = useState(null);
  const [saveGistID, setSaveGistID] = useState("");
  const [saveGistSvg, setSaveGistSvg] = useState("");
  const [showNewExport, setShowNewExport] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [showMessageBarGithub, setShowMessageBarGithub] = useState(false);
  const [showNewPage, setShowNewPage] = useState(false);
  const [showPopup4, setShowPopup4] = useState(false);
  const [open, setOpen] = useState(false);
  const [svgContentState, setSvgContentState] = useState(null);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const Menuopen = Boolean(anchorEl);
  const [profileBar, setProfileBar] = useState(null);
  const [saveSvgPic, setSaveSvgPic] = useState(false);
  const [savePngPic, setSavePngPic] = useState(false);
  const profileOpen = Boolean(profileBar);

  // Function to handle profile button click
  const handleProfileBarClick = (event) => {
    setProfileBar(event.currentTarget);
  };

  // Function to handle profile button close
  const handleProfileClose = () => {
    setProfileBar(null);
  };
  const backgroundColor = "#b5462a"; // Set the background color of the header

  const getTheMermaidDiagram = localStorage.getItem("mermaidSvg");
  // set the mermaid diagram svg in localStorage
  console.log("getTheMermaidDiagram ===>", getTheMermaidDiagram);

  /**
   * Function to fetch files from OneDrive using Microsoft Graph API.
   * It sends a GET request to retrieve files from the root directory of the user's OneDrive.
   */
  const oneDriveGetFilesApi = () => {
    // Creating a new request object for the Microsoft Graph API
    const request = new Request(`https://graph.microsoft.com/v1.0/me/drive/root/children`, {
      method: "GET", // HTTP method to fetch data
      headers: {
        Authorization: `Bearer ${OneDriveApiToken}`, // Adding the authorization token in the request headers
      },
    });
    // Fetching data from the OneDrive API
    fetch(request)
      .then((response) => {
        // Checking if the response status is not OK
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`); // Throwing an error if the response is not successful
        }
        return response.json(); // Parsing the response to JSON format
      })
      .then((data) => {
        setOneDriveFiles(data.value); // Setting the fetched files to the state
        // setShowNewExport(false); // Optionally hide the export dialog (commented out)
      })
      .catch((error) => {
        if (error.message) {
          console.log("Error:", error); // Logging the error message to the console
          setError(error.message); // Setting the error message to the state
        }
      });
  };

  /**
   * Function to toggle the visibility of the popup.
   * It switches the showPopup4 state between true and false.
   */

  const togglePopup4 = () => {
    setShowPopup4(!showPopup4); // Toggling the showPopup4 state
  };

  // console.log("oneDrive File Id==>", oneDriveId);
  // Retrieve GitHub access token from local storage
  const GithubAccessToken = localStorage.getItem("token");

  /**
   * Function to fetch code from a GitHub Gist.
   * It checks if the fetched code contains mermaid keywords and sets the corresponding state.
   *
   */
  const getGithubGistCode = (urlData) => {
    // Array of keywords to identify mermaid diagrams
    const mermaidKeywords = [
      "sequenceDiagram",
      "flowchart",
      "classDiagram",
      "stateDiagram",
      "erDiagram",
      "gantt",
      "journey",
      "gitGraph",
      "pie",
      "mindmap",
      "quadrantChart",
      "xychart-beta",
      "block-beta",
    ];
    // Fetching the GitHub Gist code
    return fetch(saveGistID || urlData, {
      method: "GET", // HTTP GET method to retrieve data
    })
      .then((response) => response.text()) // Parsing the response as text
      .then((data) => {
        // Checking if the data contains any mermaid keywords
        const isMermaidCode = mermaidKeywords.some((keyword) => data.includes(keyword));
        if (isMermaidCode) {
          setInputValue(data); // Setting the input value state with the fetched data
          setMarkdown(data); // Setting the markdown state with the fetched data (assumed to be mermaid code)
        } else {
          setInputValue(data); // Setting the input value state with the fetched data
          setGraphvizCode(data); // Setting the graphviz code state with the fetched data
        }
      })
      .catch((error) => {
        console.error("Error creating Gist:", error); // Logging any errors that occur during the fetch operation
      });
  };

  /**
   * Function to log in to OneDrive using the provided access token.
   * It fetches user data from OneDrive and stores it in local storage.
   *
   */
  const getOneDrivelogin = (access_token) => {
    const apiUrl = "https://graph.microsoft.com/v1.0/me";
    // Logging the access token for debugging purposes
    console.log("accessToken ==>", access_token);

    // Storing the access token in local storage
    localStorage.setItem("oneDrive_token", access_token);

    // Fetching OneDrive user data
    return fetch(apiUrl, {
      method: "GET", // HTTP GET method to retrieve data
      headers: {
        Authorization: `Bearer ${access_token}`, // Authorization header with the Bearer token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok"); // Throwing an error if the response is not ok
        }
        return response.json(); // Parsing the response as JSON
      })
      .then((data) => {
        // Logging the OneDrive user data for debugging purposes
        console.log("OneDrive User Data:", data);
        // Updating the state with the user data

        setOneDriveUserData(data);
        // Storing the user data in local storage
        localStorage.setItem("oneDrive_data", JSON.stringify(data));
        // Closing the OneDrive popup
        setShowOneDrivePopup(false);
        // Clearing the user token state
        setOneDriveUserToken("");
      })
      .catch((error) => {
        // Logging any errors that occur during the fetch operation
        console.error("OneDrive Error", error);
      });
  };

  /**
   * Function to fetch user data from the GitHub API.
   * It stores the fetched user data in local storage and updates the state.
   *
   */

  // Add a Backend Api in

  async function getUserData() {
    try {
      // Fetching user data from the GitHub API
      const response = await fetch("https://api.github.com/user", {
        method: "GET", // HTTP GET method to retrieve data
        headers: {
          Authorization: "Bearer " + userToken, // Authorization header with the Bearer token
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Throwing an error if the response is not ok
      }
      // Parsing the response as JSON
      const data = await response.json();
      // Logging the user data for debugging purposes
      console.log("user data ===>", data);
      localStorage.setItem("user_data", JSON.stringify(data));
      // Updating the state with the user data
      setUserInfo(data);
      // Toggling the GitHub popup
      setShowGithubPopup(!showGithubPopup);
      // Clearing the user token state
      setUserToken("");
    } catch (error) {
      // Logging any errors that occur during the fetch operation
      console.error("Error:", error);
    }
  }

  /**
   * Function to handle import authorization call.
   * It sets the state to show the popup and hides the new page.
   */
  const handleAuthCallImport = () => {
    setShowPopup(true); // Show the popup for authorization
    setShowNewPage(false); // Hide the new page
  };

  /**
   * Function to handle export authorization call.
   * It sets the state to show the popup and hides the new export page.
   */

  const handleAuthCallExport = () => {
    setShowPopup(true); // Show the popup for authorization
    setShowNewExport(false); // Hide the new export page
  };
  /**
   * Function to fetch SVG data from a GitHub Gist.
   * It retrieves the Gist data, converts Mermaid code to an image, and shows a message bar.
   *
   */
  const getGithubGistSvg = () => {
    return fetch(saveGistSvg, {
      method: "GET", // HTTP GET method to retrieve data
    })
      .then((response) => response.text()) // Parsing the response as text
      .then((data) => {
        console.log("data ==>", data); // Logging the data for debugging purposes
        convertMermaidToImage(data); // Converting Mermaid code to an image
        setShowMessageBarGithubGist(true); // Showing the message bar for GitHub Gist
      })
      .catch((error) => {
        console.error("Error creating Gist:", error); // Logging any errors that occur
      });
  };

  /**
   * Function to upload a file to OneDrive.
   * It uploads the current markdown and graphviz code as a text file to OneDrive.
   *
   * */
  const uploadFileToOneDrive = () => {
    // Creating a Blob object for the markdown content
    const fileContent = new Blob([markdown], {
      type: "text/plain", // Setting the content type to plain text
    });
    // Retrieving the graphviz code from local storage and creating a Blob object
    const getgraphls = localStorage.getItem("graphviz");
    const graphviz = new Blob([getgraphls], {
      type: "text/plain", // Setting the content type to plain text
    });

    // Creating a PUT request to upload the file to OneDrive

    const request = new Request(
      `https://graph.microsoft.com/v1.0/me/drive/root:/${getUserFileNameToOneDrive}.txt:/content`,
      {
        method: "PUT", // HTTP PUT method to upload data
        headers: {
          Authorization: `Bearer ${OneDriveApiToken}`, // Authorization header with the Bearer token
          "Content-Type": "application/octet-stream", // Setting the content type to octet-stream
        },
        body: fileContent && graphviz, // Setting the request body with the file content
      }
    );
    // Fetching the request
    fetch(request)
      .then((response) => response.json()) // Parsing the response as JSON
      .then((data) => {
        // Setting the OneDrive file ID and storing it in local storage
        setOneDriveId(data.id);
        localStorage.setItem("oneDriveId", data.id);
        // Hiding the new export page and removing relevant items from local storage
        setShowNewExport(false);
        localStorage.removeItem("inputValue");
        localStorage.removeItem("markdown");
        localStorage.removeItem("graphviz");
        // Hiding the popup
        setShowPopup1(false);
        console.log("data ==>", data); // Logging the data for debugging purposes
      })
      .catch((error) => {
        console.log("error ==>", error); // Logging any errors that occur
      });
  };

  /**
   * Function to upload a file as SVG to OneDrive.
   * It combines markdown and graphviz content, creates a Blob object, and uploads it as an SVG file to OneDrive.
   */
  const uploadFileSvgToOneDrive = () => {
    // Creating a Blob object for the markdown content
    const fileContent = new Blob([markdown], {
      type: "text/plain", // Setting the content type to plain text
    });
    // Retrieving the graphviz code from local storage and creating a Blob object
    const getgraphls = localStorage.getItem("graphviz");
    const graphviz = new Blob([getgraphls], {
      type: "text/plain", // Setting the content type to plain text
    });
    // Combining the markdown and graphviz content into a single Blob object
    const combinedContent = new Blob([fileContent, graphviz], {
      type: "text/plain", // Setting the content type to plain text
    });

    console.log("markdown ===>", fileContent); // Logging the markdown content for debugging purposes
    // Creating a PUT request to upload the file as SVG to OneDrive
    const request = new Request(
      `https://graph.microsoft.com/v1.0/me/drive/root:/${getUserFileNameToOneDriveSvg}.svg:/content`,
      {
        method: "PUT", // HTTP PUT method to upload data
        headers: {
          Authorization: `Bearer ${OneDriveApiToken}`, // Authorization header with the Bearer token
          "Content-Type": "application/octet-stream", // Setting the content type to octet-stream
        },
        body: combinedContent, // Setting the request body with the combined content
      }
    );
    // Fetching the request
    fetch(request)
      .then((response) => response.json()) // Parsing the response as JSON
      .then((data) => {
        // Setting the OneDrive file ID and storing it in local storage
        setOneDriveId(data.id);
        localStorage.setItem("oneDriveId", data.id);
        // Hiding the new export page and relevant popups, and removing items from local storage
        setShowNewExport(false);
        setShowPopup2(false);
        localStorage.removeItem("markdown");
        localStorage.removeItem("graphviz");
        localStorage.removeItem("inputValue");
        console.log("data ==>", data); // Logging the data for debugging purposes
      })
      .catch((error) => {
        console.log("error ==>", error); // Logging any errors that occur
      });
  };

  /**
 * Function to get the content of a file from OneDrive.
 * It retrieves the content of the specified file and determines if it contains Mermaid or Graphviz code.
 *
 
 */
  const oneDriveGetContentApi = (getOneDriveFileContent) => {
    // Creating a GET request to retrieve the file content from OneDrive
    const request = new Request(`https://graph.microsoft.com/v1.0/me/drive/items/${getOneDriveFileContent}/content`, {
      method: "GET", // HTTP GET method to retrieve data
      headers: {
        Authorization: `Bearer ${OneDriveApiToken}`, // Authorization header with the Bearer token
      },
    });
    // Fetching the request
    fetch(request)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`); // Throwing an error if the response is not OK
        }
        return response.text(); // Parsing the response as text
      })
      .then((data) => {
        console.log("github gist data get===>", data); // Logging the retrieved data for debugging purposes
        // List of Mermaid keywords to check if the content is Mermaid code
        const mermaidKeywords = [
          "sequenceDiagram",
          "flowchart",
          "classDiagram",
          "stateDiagram",
          "erDiagram",
          "gantt",
          "journey",
          "gitGraph",
          "pie",
          "mindmap",
          "quadrantChart",
          "xychart-beta",
          "block-beta",
        ];
        // Checking if the content contains any Mermaid keywords
        const isMermaidCode = mermaidKeywords.some((keyword) => data.toLowerCase().startsWith(keyword.toLowerCase()));

        if (isMermaidCode) {
          // If the content is Mermaid code, set the input value and markdown state
          setInputValue(data);
          setMarkdown(data);
          setGraphvizCode("");
        } else {
          // If the content is Graphviz code, set the input value and graphviz state
          setInputValue(data);
          setGraphvizCode(data);
          setMarkdown("");
        }
      })
      .catch((error) => {
        console.log("error ==>", error); // Logging any errors that occur
      });
  };

  //OneDrive Get Files
  const getoneDriveId = localStorage.getItem("oneDriveId");
  // save in localStorage oneDriveId

  // console.log("localStorageGet Drive id ==>", getoneDriveId);
  /**
   * Function to handle fetching all Gists from GitHub.
   * Retrieves all Gists for the authenticated user and updates the state with the file details.
   */
  const handleGetAllGist = () => {
    const accessToken = GithubAccessToken; // Retrieve GitHub access token from local storage
    const apiUrl = "https://api.github.com/gists"; // GitHub API URL to fetch Gists

    console.log("accessToken api ===>", accessToken); // Log the access token for debugging purposes

    fetch(apiUrl, {
      method: "GET", // HTTP GET method to retrieve data
      headers: {
        Authorization: `Bearer ${accessToken}`, // Authorization header with the Bearer token
      },
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        const allFileDetails = [];
        // Iterate through each Gist and extract file details
        data.forEach((gist) => {
          const files = gist.files;

          Object.keys(files).forEach((fileName) => {
            const file = files[fileName];
            allFileDetails.push({ fileName, rawUrl: file.raw_url }); // Add file details to the array
          });
        });

        setFileDetails(allFileDetails); // Update the state with file details
      })
      .catch((error) => {
        console.error("Error creating Gist:", error); // Log any errors that occur
      });
  };

  /**
   * Function to save SVG content to a GitHub Gist.
   * Creates a new Gist with the provided content and updates the state accordingly.
   */
  const saveSVGToGitHubGist = () => {
    // List of Mermaid keywords to check if the content is Mermaid code
    const mermaidKeywords = [
      "sequenceDiagram",
      "flowchart",
      "classDiagram",
      "stateDiagram",
      "erDiagram",
      "gantt",
      "journey",
      "gitGraph",
      "pie",
      "mindmap",
      "quadrantChart",
      "xychartbeta",
      "blockbeta",
    ];

    const getgraphls = localStorage.getItem("graphviz"); // Retrieve the graphviz code from local storage

    const Graphvizcontent = getgraphls; // Set the graphviz content
    const Graphvizfilename = "Graphviz.graph"; // Set the filename for graphviz content
    const accessToken = GithubAccessToken; // Retrieve GitHub access token from local storage
    const filename = "Markdown.mmd"; // Set the filename for markdown content
    const content = markdown; // Set the markdown content
    const apiUrl = "https://api.github.com/gists"; // GitHub API URL to create a Gist

    let files = {};
    // Check if the content contains any Mermaid keywords
    const isMermaidCode = mermaidKeywords.some((keyword) => content.includes(keyword));

    if (isMermaidCode) {
      files[filename] = {
        content,
      };
    } else {
      files[Graphvizfilename] = {
        content: Graphvizcontent,
      };
    }

    const description = isMermaidCode ? "Markdown.mmd" : "Graphviz.graph"; // Set the description based on the content type

    const data = {
      description: description, // Set the Gist description
      public: true, // Set the Gist visibility to public
      files, // Set the files to be included in the Gist
    };

    // Fetch the request to create the Gist
    return fetch(apiUrl, {
      method: "POST", // HTTP POST method to create a Gist
      headers: {
        Authorization: `Bearer ${accessToken}`, // Authorization header with the Bearer token
      },
      body: JSON.stringify(data), // Set the request body with the Gist data
    })
      .then((response) => response.json()) // Parse the response as JSON
      .then((data) => {
        console.log("Gist created:", data.html_url); // Log the Gist URL for debugging purposes
        const gistUrl = data.html_url; // Get the Gist URL from the response
        window.location.href = gistUrl; // Redirect to the Gist URL
        setShowNewExport(false); // Hide the new export page
        const markdownTextRawUrl = data?.files["Markdown.text"]?.raw_url; // Get the raw URL of the markdown file
        const graphvizTextRawUrl = data?.files["Graphviz.text"]?.raw_url; // Get the raw URL of the graphviz file
        console.log("Markdown Text Raw URL:", markdownTextRawUrl); // Log the markdown raw URL for debugging purposes
        setSaveGistSvg(markdownTextRawUrl && graphvizTextRawUrl); // Update the state with the raw URLs
        setInputValue(""); // Clear the input value
        setMarkdown(""); // Clear the markdown content
        setGraphvizCode(""); // Clear the graphviz content
        localStorage.remove("graphviz"); // Remove the graphviz content from local storage
        localStorage.remove("markdown"); // Remove the markdown content from local storage
        localStorage.removeItem("inputValue"); // Remove the input value from local storage
      })
      .catch((error) => {
        console.error("Error creating Gist:", error); // Log any errors that occur
      });
  };

  /**
   * Function to handle tab bar change.
   * Updates the tab bar value and hides the anchor element if the new value is 0.
   *
   *
   */

  const tabBarHandleChange = (event, newValue) => {
    if (newValue == 0) {
      setTabBarValue(1); // Set the tab bar value to 1
      setAnchorEl(false); // Hide the anchor element
      return;
    }

    setTabBarValue(newValue); // Update the tab bar value
  };

  const handleHamburgerClick = () => {
    // Handle click on hamburger menu
    if (userData || onDriveData) {
      console.log("if condition ==>"); // Log condition
      setShowNewPage(true); // Show new page
      setAnchorEl(false); // Close anchor element
      handleGetAllGist(); // Handle getting all gists
      oneDriveGetFilesApi(); // Get OneDrive files API
    } else {
      console.log("else condition ==>"); // Log else condition
      setShowNewPage(false); // Hide new page
      setAnchorEl(false); // Close anchor element
      setNoLoginState(true); // Set no login state
    }
  };

  const handleDrawerClose = () => {
    // Close drawer
    setShowMessageBarGithubGist(false); // Hide GitHub Gist message bar
    setShowNewPage(false); // Hide new page
  };

  const handleClickOpen = (item) => {
    // Handle click to open
    // setOpen(true);
    setShowNewPage(false); // Hide new page
    setGalleryModal(false); // Set gallery modal to false
    setTimeout(() => {
      getGithubGistCode(item.rawUrl); // Get GitHub Gist code from raw URL
    }, 1500);
    // setFolderData(item);
  };

  const handleOpenOneDriveOpenImportCode = (item) => {
    // Handle opening OneDrive and importing code
    console.log("oneDrive File Id", item); // Log OneDrive file ID
    oneDriveGetContentApi(item); // Get OneDrive content API
    setShowNewPage(false); // Hide new page
    // setGalleryModal(false)
  };

  const handleCloseHamburgerClick = () => {
    // Handle closing hamburger menu click
    setShowNewPage(false); // Hide new page
    setShowNewExport(false); // Hide new export
  };
  const handleClick = (event) => {
    // Handle click event
    setAnchorEl(event.currentTarget); // Set anchor element
  };
  const handleMenuClose = () => {
    // Handle closing menu
    setAnchorEl(null); // Set anchor element to null
  };

  const handleExportPage = () => {
    // Handle export page
    if (userData || OneDriveLocalData) {
      setShowNewExport(!showNewExport); // Toggle show new export
      setAnchorEl(false); // Set anchor element to false
    } else {
      setNoLoginState(true); // Set no login state
      setAnchorEl(false); // Set anchor element to false
    }
  };

  const handleRouteOneDrive = () => {
    // Handle routing to OneDrive
    // const githubAuthURL = "https://markify-5b6a5.web.app/onedrive_auth";
    const githubAuthURL = "https://mrk.elenta.co/microsoft/login/"; // GitHub authentication URL
    window.open(githubAuthURL, "_blank"); // Open URL in new tab
  };

  const handleTokenChange = (event, newValue) => {
    // Handle token change
    setUserToken(newValue); // Set user token
    localStorage.setItem("token", newValue); // Store token in local storage
  };

  const handleOneDriveTokenChange = (event, newValue) => {
    // Handle OneDrive token change
    setOneDriveUserToken(newValue); // Set OneDrive user token
  };

  const logout = () => {
    // Handle logout
    localStorage.removeItem("access_token"); // Remove access token from local storage
    localStorage.removeItem("user_data"); // Remove user data from local storage
    localStorage.removeItem("oneDrive_data"); // Remove OneDrive data from local storage
    localStorage.removeItem("token"); // Remove token from local storage
    localStorage.removeItem("oneDrive_token"); // Remove OneDrive token from local storage
    setOneDriveUserData({}); // Clear OneDrive user data
    handleProfileClose(); // Handle closing profile
    setUserInfo({}); // Clear user info
    setShowNewPage(false); // Hide new page
    setShowNewExport(false); // Hide new export
  };

  // Modal component function
  const handleIconClick = () => {
    setIsModalOpen(true); // Set modal open to true
  };

  const toggleGithub = () => {
    setShowGithubPopup(!showGithubPopup); // Toggle show GitHub popup
    setShowPopup(false); // Hide popup
  };

  const toggleOneDrive = () => {
    setShowOneDrivePopup(!showOneDrivePopup); // Toggle show OneDrive popup
    setShowPopup(false); // Hide popup
  };

  const togglePopup = (event) => {
    if (onDriveData || userData) {
      handleProfileBarClick(event); // Handle profile bar click if OneDrive data or user data is present
    }
    setShowPopup(!showPopup); // Toggle show popup
  };

  const togglePopup1 = () => {
    setShowPopup1(!showPopup1); // Toggle show popup 1
  };

  const togglePopup2 = () => {
    setShowPopup2(!showPopup2); // Toggle show popup 2
  };

  const togglePopup3 = () => {
    setShowPopup3(!showPopup3); // Toggle show popup 3
  };
  const addCodeGithubGist = () => {
    // Add code to GitHub Gist
    const mermaidKeywords = [
      "sequenceDiagram",
      "flowchart",
      "classDiagram",
      "stateDiagram",
      "erDiagram",
      "gantt",
      "journey",
      "gitGraph",
      "pie",
      "mindmap",
      "quadrantChart",
      "xychart-beta",
      "block-beta",
    ];

    const getgraphls = localStorage.getItem("graphviz"); // Get graphviz from local storage

    const accessToken = GithubAccessToken; // GitHub access token
    const filename = "Markdown.text"; // File name for Markdown
    const Graphvizfilename = "Graphviz.text"; // File name for Graphviz
    const content = markdown; // Markdown content
    const Graphvizcontent = getgraphls; // Graphviz content
    const apiUrl = "https://api.github.com/gists"; // GitHub API URL
    let files = {};

    const isMermaidCode = mermaidKeywords.some((keyword) => content.includes(keyword)); // Check if content includes any mermaid keyword

    if (isMermaidCode) {
      files[filename] = {
        content,
      };
    } else {
      files[Graphvizfilename] = {
        content: Graphvizcontent,
      };
    }

    const description = isMermaidCode ? "Markdown.mmd" : "Graphviz.graph"; // Description based on content type

    const data = {
      description: description,
      public: true,
      files,
    };

    return fetch(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Gist created:", data); // Log gist creation
        const gistUrl = data.html_url; // Gist URL
        window.location.href = gistUrl; // Redirect to gist URL
        const markdownTextRawUrl = data?.files["Markdown.text"]?.raw_url; // Raw URL for Markdown text
        const graphvizTextRawUrl = data?.files["Graphviz.text"]?.raw_url; // Raw URL for Graphviz text
        console.log("Markdown Text Raw URL:", graphvizTextRawUrl); // Log Markdown text raw URL
        setSaveGistID(markdownTextRawUrl && graphvizTextRawUrl); // Set saved gist ID
        setGithubShareModal(false); // Hide GitHub share modal
        setShowNewExport(false); // Hide new export
        localStorage.removeItem("inputValue"); // Remove input value from local storage
        setInputValue(""); // Reset input value
        setMarkdown(""); // Reset Markdown
        setGraphvizCode(""); // Reset Graphviz code
        localStorage.removeItem("graphviz"); // Remove Graphviz from local storage
        localStorage.removeItem("markdown"); // Remove Markdown from local storage
      })
      .catch((error) => {
        console.error("Error creating Gist:", error); // Log error creating Gist
      });
  };
  const SessionExpire = (
    // Session expiration component
    <div>
      <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
        <Image src={OneDrive} style={{ width: "55px", height: "55px", borderRadius: "26px", marginRight: "10px" }} />{" "}
        {/* OneDrive image */}
        <Text variant="small" style={{ fontFamily: "Montserrat" }}>
          Session Expired {/* Session expired text */}
        </Text>
        <Text variant="small" style={{ fontFamily: "Montserrat" }}>
          If your Azure token has expired, you'll need to log out and then log in again {/* Token expiration message */}
        </Text>
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <PrimaryButton
            onClick={() => logout()}
            text="Logout" // Logout button text
            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }} // Button style
          />
          {/* <PrimaryButton
            onClick={() => setShowPopup1(false)}
            text="Cancel"
            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
          /> */}
        </div>
      </Stack>
    </div>
  );

  const iconStyle = {
    width: "23px", // Icon width
    height: "23px", // Icon height
    borderRadius: "20px", // Icon border radius
  };
  // Component for GitHub authentication
  const GithubContent = (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
      {/* Header */}
      <Text variant="xLarge">Action required</Text>
      {/* Instruction for GitHub login */}
      <p style={{ fontSize: 12 }}>To log in with GitHub, please open the following URL in a browser window</p>
      {/* Displaying GitHub authentication URL */}
      <TextField disabled={true} value={value} />
      {/* Token input for authentication */}
      <p style={{ fontSize: 12 }}>After completing the authentication flow, paste in the resulting token:</p>
      <TextField placeholder="<Paste token here>" type="text" value={userToken} onChange={handleTokenChange} />
      {/* Save and Cancel buttons */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          gap: 15,
        }}
      >
        <PrimaryButton
          text="Save"
          onClick={getUserData}
          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "20px" }}
        />
        <PrimaryButton
          text="Cancel"
          onClick={() => setShowGithubPopup(false)}
          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "20px" }}
        />
      </div>
    </Stack>
  );

  // Component for OneDrive authentication

  const OneDriveContent = (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
      {/* Header */}
      <Text variant="xLarge">Action required</Text>
      {/* Instruction for OneDrive login */}
      <p style={{ fontSize: 12 }}>To log in with OneDrive, please open the following URL in a browser window</p>
      {/* Button to initiate OneDrive authentication */}
      <DefaultButton
        primary
        text="OneDrive"
        style={{
          width: "120px",
          height: "36px",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
          border: "none",
          borderRadius: "20px",
        }}
        onClick={handleRouteOneDrive}
      >
        <img src={OneDrive} alt="GitHub Icon" style={iconStyle} />
      </DefaultButton>
      {/* Token input for OneDrive authentication */}
      <p style={{ fontSize: 12 }}>After completing the authentication flow, paste in the resulting token:</p>
      <TextField
        placeholder="<Paste token here>"
        type="text"
        value={oneDriveUserToken}
        onChange={handleOneDriveTokenChange}
      />
      {/* Save and Cancel buttons */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          gap: 15,
        }}
      >
        <PrimaryButton
          text="Save"
          onClick={() => getOneDrivelogin(oneDriveUserToken)}
          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "20px" }}
        />
        <PrimaryButton
          text="Cancel"
          onClick={() => setShowOneDrivePopup(false)}
          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "20px" }}
        />
      </div>
    </Stack>
  );

  const onDriveData = JSON.parse(localStorage.getItem("oneDrive_data"));

  const userData = JSON.parse(localStorage.getItem("user_data"));
  const OneDriveApiToken = localStorage.getItem("oneDrive_token");

  // Component for displaying user data after authentication
  const content = (
    <div>
      {/* Displaying user data if available */}
      {onDriveData || userData ? (
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
          <Text variant="large" style={{ fontFamily: "Montserrat" }}>
            Username: {onDriveData?.displayName || userData?.name}
          </Text>
          {/* Account type */}
          <Text variant="xLarge" style={{ fontFamily: "Montserrat" }}>
            Account: {userData ? "GitHub" : "OneDrive"}
          </Text>
          {/* Logout and Cancel buttons */}
          <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
            <PrimaryButton
              onClick={logout}
              text="Logout"
              style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
            />
            <PrimaryButton
              onClick={() => setShowPopup(false)}
              text="Cancel"
              style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
            />
          </div>
        </Stack>
      ) : (
        // Display login options if no user data available
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", marginTop: 23 }}>
          <p style={{ fontSize: 20, fontFamily: "Montserrat" }}>Login</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              gap: 12,
              marginTop: 15,
            }}
          >
            {/* GitHub login button */}
            <PrimaryButton
              onClick={toggleGithub}
              text="Github"
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                borderRadius: "10px",
                height: 40,
                color: "black",
              }}
            >
              <img src={github} alt="GitHub Icon" style={iconStyle} />
            </PrimaryButton>
            {/* OneDrive login button */}
            <PrimaryButton
              onClick={toggleOneDrive}
              text="OneDrive"
              style={{
                backgroundColor: "#fff",
                border: "1px solid grey",
                borderRadius: "10px",
                height: 40,
                color: "black",
              }}
            >
              <img src={microSoftImage} alt="OneDrive Icon" style={iconStyle} />
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );

  // get the File Name by uer
  const handleFileNameChange = (e) => {
    setGetUserFileNameToOneDrive(e.target.value);
  };
  const handleFileNameSvgChange = (e) => {
    setGetUserFileNameToOneDriveSvg(e.target.value);
  };
  // Component for requesting file name input for OneDrive
  const FileNameModal = (
    <div>
      <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
        {/* Icon */}
        <Image src={OneDrive} style={{ width: "55px", height: "55px", borderRadius: "26px", marginRight: "10px" }} />
        {/* Instruction */}
        <Text variant="small" style={{ fontFamily: "Montserrat" }}>
          If you are saving a Mermaid code file on OneDrive, could you please provide me with the filename for that
          Mermaid code file in the input.
        </Text>
        {/* Filename input */}
        <TextField
          placeholder="<Paste FileName here>"
          type="text"
          value={getUserFileNameToOneDrive}
          onChange={handleFileNameChange}
        />
        {/* <Text variant="xLarge" style={{ fontFamily: "Montserrat" }}>
          Account: {userData ? "GitHub" : "OneDrive"}
        </Text> */}
        {/* Save and Cancel buttons */}
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <PrimaryButton
            onClick={() => uploadFileToOneDrive()}
            text="Save"
            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
          />
          <PrimaryButton
            onClick={() => setShowPopup1(false)}
            text="Cancel"
            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
          />
        </div>
      </Stack>
    </div>
  );
  // Component for requesting file name input for SVG on OneDrive
  const FileNameSvgModal = (
    <div>
      <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
        {/* Icon */}
        <Image src={OneDrive} style={{ width: "55px", height: "55px", borderRadius: "26px", marginRight: "10px" }} />

        {/* Instruction */}
        <Text variant="small" style={{ fontFamily: "Montserrat" }}>
          If you are saving a Mermaid code Svg file on OneDrive, could you please provide me with the filename for that
          Mermaid code file in the input.
        </Text>
        {/* Filename input */}
        <TextField
          placeholder="<Paste FileName here>"
          type="text"
          value={getUserFileNameToOneDriveSvg}
          onChange={handleFileNameSvgChange}
        />
        {/* Save and Cancel buttons */}
        <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
          <PrimaryButton
            onClick={() => uploadFileSvgToOneDrive()}
            text="Save"
            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
          />
          <PrimaryButton
            onClick={() => setShowPopup2(false)}
            text="Cancel"
            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
          />
        </div>
      </Stack>
    </div>
  );

  const noLogintogglePopup = () => {
    setNoLoginState(!noLoginState);
  };

  const handleAuthAccounts = () => {
    setShowPopup(true);
    setNoLoginState(false);
  };

  // Component for prompting user to log in if no accounts are found
  const NoAccountModal = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 10,
        alignItems: "center",
        marginTop: 33,
        alignContent: "center",
      }}
    >
      {/* Icon */}
      <NoAccountsIcon style={{ display: "flex", alignItems: "center", alignSelf: "center" }} sx={{ fontSize: 33 }} />
      {/* Error message */}
      <Text
        style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500", textAlign: "center" }}
      >
        Login, if you want to import/export mermaid/SVG
      </Text>

      <PrimaryButton
        text="Login"
        onClick={handleAuthAccounts}
        style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px", width: "47%" }}
      />
    </div>
  );

  const [layout, setLayout] = React.useState(undefined);

  // Function to handle opening the login modal
  const handleLoginModal = () => {
    setShowPopup(true); // Show the login modal
    setIsModalOpen(false); // Close the current modal
  };

  // Function to handle closing the current modal
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the current modal
  };

  // Function to handle alert for GitHub authentication
  const handleAlertGithub = () => {
    if (userData) {
      setGithubShareModal(true); // Show GitHub share modal
      setIsModalOpen(false); // Close the current modal
    } else {
      setShowMessageBarGithub(true); // Show message bar for GitHub login
    }
  };

  // Function to handle alert for OneDrive authentication
  const handleAlert = (user_data) => {
    if (user_data) {
      setShowMessageBar(true); // Show message bar for OneDrive
    } else {
      setOneDriveShareModal(true); // Show OneDrive share modal
      setIsModalOpen(false); // Close the current modal
    }
  };

  // Template modal content

  const templateModal = (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill gap={20} style={{ padding: "20px" }}>
      <Text variant="xLarge" style={{ fontFamily: "Montserrat" }}>
        Template Desgin
      </Text>
      <p style={{ fontSize: 12 }}>To log in with OneDrive, please open the following URL in a browser window</p>
      {/* Button to initiate OneDrive authentication */}
      <DefaultButton
        primary
        text="OneDrive"
        style={{
          width: "120px",
          height: "36px",
          marginRight: "10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
          border: "none",
          borderRadius: "20px",
        }}
        onClick={handleRouteOneDrive}
      >
        <img src={OneDrive} alt="GitHub Icon" style={iconStyle} />
      </DefaultButton>
      <p style={{ fontSize: 12 }}>After completing the authentication flow, paste in the resulting token:</p>
      {/* Save and Cancel buttons */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          gap: 15,
        }}
      >
        <PrimaryButton
          text="Save"
          onClick={() => getOneDrivelogin(oneDriveUserToken)}
          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "20px" }}
        />
        <PrimaryButton
          text="Cancel"
          onClick={() => setShowOneDrivePopup(false)}
          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "20px" }}
        />
      </div>
    </Stack>
  );

  // Function to determine header text based on conditions
  const headerText = () => {
    if (isSwitchChecked) {
      return "Online Template";
    } else if (open) {
      return "Templates";
    } else if (activeButton === "graphviz") {
      return "Graphviz";
    } else if (activeButton === "mermaid") {
      return "Mermaid";
    } else if (activeButton === "Categories") {
      return "Categories";
    } else if (activeButton === "templates") {
      return "My Diagrams";
    } else {
      return "Code View";
    }
  };

  // Function to handle opening save diagram modal
  const handleSaveDiagram = () => {
    setSaveModalOpen(true); // Open save diagram modal
  };

  // Function to convert Graphviz code to image format
  const convertIntoGraphvizToImage = (graphvizResult, saveType) => {
    const viz = new Viz({ Module, render });
    viz
      .renderString(graphvizResult)
      .then((graphvizResult) => {
        if (saveType === "svg") {
          saveAsSvgFile(graphvizResult); // Save as SVG file
        } else if (saveType === "png") {
          saveFile(graphvizResult); // Save as PNG file
        }
      })
      .catch((graphvizError) => {
        console.log("Error rendering Graphviz code:", graphvizError);
      });
  };

  // Function to convert Mermaid code to image format
  const convertIntoMermaidToImage = (mermaidCode, saveType) => {
    mermaid
      .render("mermaid", mermaidCode)
      .then((svgImage) => {
        const svgString = svgImage.svg;
        console.log("Generated SVG Content:", svgString);
        if (saveType === "svg") {
          saveAsSvgFile(svgString); // Save as SVG file
        } else if (saveType === "png") {
          saveFile(svgString); // Save as PNG file
        }
      })
      .catch((error) => {
        console.log("Error rendering Mermaid diagram:", error);
        setSvgContentState("");
      });
  };

  // Function to handle saving diagram as PNG
  const handleSaveAsPic = () => {
    setSaveModalOpen(false); // Close save modal
    if (markdown) {
      convertIntoMermaidToImage(markdown, "png"); // Convert Mermaid to PNG
    } else if (graphvizCode) {
      convertIntoGraphvizToImage(graphvizCode, "png"); // Convert Graphviz to PNG
    }
  };

  // Function to handle saving diagram as SVG
  const handleSaveAsPicSvg = () => {
    setSaveModalOpen(false); // Close save modal
    if (markdown) {
      convertIntoMermaidToImage(markdown, "svg"); // Convert Mermaid to SVG
    } else if (graphvizCode) {
      convertIntoGraphvizToImage(graphvizCode, "svg"); // Convert Graphviz to SVG
    }
  };

  // Function to handle saving code to file
  const handleSaveAsCode = () => {
    setSaveModalOpen(false); // Close save modal
    const code = markdown || graphvizCode;
    saveCodeToFile(code); // Save code to file
  };

  const saveCodeToFile = async (codeContent, filename = "diagram.md") => {
    try {
      const codeBlob = new Blob([codeContent], { type: "text/plain" });

      // Check if the showSaveFilePicker API is available
      if (window.showSaveFilePicker) {
        // Create a file handle
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: filename,
          types: [
            {
              description: "Text file",
              accept: { "text/plain": [".html", ".md"] },
            },
          ],
        });

        // Create a writable stream
        const writable = await fileHandle.createWritable();

        // Write the code content to the file
        await writable.write(codeBlob);

        // Close the file and save the changes
        await writable.close();

        // Show a message to the user to open the file in VS Code
        alert(`File saved as ${filename}. You can open it in VS Code.`);
      } else {
        // Fallback for browsers that do not support showSaveFilePicker
        const url = window.URL.createObjectURL(codeBlob);
        triggerCodeDownload(url, filename);
        window.URL.revokeObjectURL(url);

        // Show a message to the user to open the file in VS Code
        alert(`File downloaded as ${filename}. You can open it in VS Code.`);
      }
    } catch (error) {
      console.error("Error saving file:", error);
    }
  };

  // Function to trigger download of a file from a given URL with a specified filename
  const triggerCodeDownload = (url, filename) => {
    // Create an <a> element in the DOM
    const a = document.createElement("a");
    // Set the href attribute of the <a> element to the file's URL
    a.href = url;
    // Set the download attribute of the <a> element to the desired filename
    a.download = filename;
    // Append the <a> element to the document body
    document.body.appendChild(a);
    // Simulate a click on the <a> element to trigger the download
    a.click();
    // Remove the <a> element from the document body after download is initiated
    document.body.removeChild(a);
  };

  const saveFile = async (svgContent, filename = "diagram.png") => {
    try {
      // Create a temporary SVG element
      const tempSvg = new Blob([svgContent], { type: "image/svg+xml" });
      const url = window.URL.createObjectURL(tempSvg);

      // Create an image element
      const img = document.createElement("img");
      img.onload = async () => {
        // Create a canvas element
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate the aspect ratio and set the canvas dimensions
        const aspectRatio = img.height / img.width;
        canvas.width = 720;
        canvas.height = 720 * aspectRatio;

        // Draw the SVG image onto the canvas
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a PNG blob
        const pngBlob = await new Promise((resolve) => canvas.toBlob(resolve, "image/png"));

        // Check if the showSaveFilePicker API is available
        if (window.showSaveFilePicker) {
          // Create a file handle
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: filename,
            types: [
              {
                description: "PNG file",
                accept: { "image/png": [".png"] },
              },
            ],
          });
          // Create a writable stream
          const writable = await fileHandle.createWritable();

          // Write the PNG content to the file
          await writable.write(pngBlob);

          // Close the file and save the changes
          await writable.close();
        } else {
          // Fallback for browsers that do not support showSaveFilePicker
          const url = window.URL.createObjectURL(pngBlob);
          triggerDownload(url, filename);
          window.URL.revokeObjectURL(url);
        }
      };

      // Set the src of the image element to the SVG URL
      img.src = url;
    } catch (error) {
      console.error("Error saving file:", error);
    }
  };
  console.log("savesvg state ===>", saveSvgPic);
  const saveAsSvgFile = async (svgContent, filename = "diagram.svg") => {
    try {
      // Ensure the SVG content is a string
      if (typeof svgContent !== "string") {
        console.error("Invalid SVG content: Not a string");
        return;
      }

      // Parse the SVG content
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgContent, "image/svg+xml");

      // Check for parsing errors
      const parseError = svgDoc.querySelector("parsererror");
      if (parseError) {
        console.error("Invalid SVG content: Parsing error", parseError.textContent);
        return;
      }

      const svgElement = svgDoc.documentElement;

      // Set width and height attributes to 720px
      svgElement.setAttribute("width", "720");
      svgElement.setAttribute("height", "720");

      // Serialize the updated SVG content
      const serializer = new XMLSerializer();
      const updatedSvgContent = serializer.serializeToString(svgElement);

      // Create a Blob from the updated SVG content
      const svgBlob = new Blob([updatedSvgContent], { type: "image/svg+xml" });

      // Check if the showSaveFilePicker API is available
      if (window.showSaveFilePicker) {
        // Create a file handle
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: filename,
          types: [
            {
              description: "SVG file",
              accept: { "image/svg+xml": [".svg"] },
            },
          ],
        });

        // Create a writable stream
        const writable = await fileHandle.createWritable();

        // Write the SVG content to the file
        await writable.write(svgBlob);

        // Close the file and save the changes
        await writable.close();
      } else {
        const url = window.URL.createObjectURL(svgBlob);
        triggerDownloadSvg(url, filename);
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error saving file:", error);
    }
  };

  // Function to trigger download of an SVG file from a given URL with a specified filename
  const triggerDownloadSvg = (url, filename) => {
    // Create an <a> element in the DOM
    const a = document.createElement("a");
    // Set the href attribute of the <a> element to the SVG file's URL
    a.href = url;
    // Set the download attribute of the <a> element to the desired filename
    a.download = filename;
    // Append the <a> element to the document body
    document.body.appendChild(a);
    // Simulate a click on the <a> element to trigger the download
    a.click();
    // Remove the <a> element from the document body after download is initiated
    document.body.removeChild(a);
  };

  // Function to trigger download of a file from a given URL with a specified filename
  const triggerDownload = (url, fileName) => {
    // Create an <a> element in the DOM
    const link = document.createElement("a");
    // Set the href attribute of the <a> element to the file's URL
    link.href = url;
    // Set the download attribute of the <a> element to the desired filename
    link.download = fileName;
    // Simulate a click on the <a> element to trigger the download
    link.click();
  };

  return (
    <>
      <div style={{ backgroundColor, padding: "3px" }}>
        {/* Outer container with background color and padding */}
        <Stack horizontal verticalAlign="center">
          {/* Stack layout for horizontal alignment */}
          <Stack.Item grow></Stack.Item>
          {/* Empty stack item to push content to the right */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flex: 1,
              alignItems: "center",
            }}
          >
            {/* Container for left-aligned content */}
            <button class="button">
              {/* Button with class name */}
              <List24Filled style={{ color: "white", cursor: "pointer", marginRight: "6px" }} onClick={handleClick} />
              {/* Icon for list view */}
            </button>
            {/* End of button */}
            <Text style={{ color: "white", fontSize: 13, fontFamily: "Montserrat", marginRight: "4px" }}>
              {" "}
              {headerText()}
            </Text>
            {/* End of text */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                justifyContent: "flex-end",
                flex: 1,
                alignContent: "center",
              }}
            >
              {/* Container for right-aligned content */}
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={Menuopen}
                onClose={handleMenuClose}
                TransitionComponent={Fade}
              >
                {/* Menu component */}
                <MenuItem onClick={handleHamburgerClick}>Import</MenuItem>
                {/* Menu item for import action */}
                <MenuItem onClick={handleExportPage}>Export</MenuItem>
                {/* Menu item for export action */}
              </Menu>
              {/* End of menu */}
              {!(onDriveData || userData) && (
                // Conditionally rendered modal for logged out users
                <Modal
                  isOpen={showPopup}
                  onDismiss={togglePopup}
                  isBlocking={false}
                  containerClassName="modal-container custom-modal"
                  styles={{
                    main: {
                      borderRadius: "15px",
                    },
                  }}
                >
                  {content}
                  {/* Modal content */}
                </Modal>
              )}
              {/* End of modal */}
              {(onDriveData || userData) && (
                // Conditionally rendered menu for logged in users
                <Menu
                  anchorEl={profileBar}
                  id="account-menu"
                  open={profileOpen}
                  onClose={handleProfileClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem onClick={handleProfileClose}>
                    {/* Menu item for user profile details */}
                    {userData?.avatar_url || onDriveData?.pic ? (
                      <Image
                        src={onDriveData?.pic || userData?.avatar_url}
                        alt="Avatar"
                        style={{ width: "37px", height: "37px", borderRadius: "26px", marginRight: "10px" }}
                      />
                    ) : (
                      <Avatar style={{ marginRight: "10px" }}>
                        {/* Avatar icon */}
                        {userData?.name?.charAt(0).toUpperCase() || onDriveData?.displayName?.charAt(0).toUpperCase()}
                      </Avatar>
                    )}
                    {/* End of avatar */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {/* Container for user name and details */}
                      <span style={{ fontSize: "16px", fontWeight: "normal", fontFamily: "Montserrat !important" }}>
                        {userData?.name || onDriveData?.displayName}
                      </span>
                      <span style={{ fontSize: "12px", color: "gray", fontFamily: "Montserrat !important" }}>
                        {userData?.login || onDriveData?.givenName}
                      </span>
                    </div>
                  </MenuItem>
                  <Divider />

                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <span style={{ fontSize: "14px", color: "gray", fontFamily: "Montserrat !important" }}>
                      Sign out
                    </span>
                  </MenuItem>
                </Menu>
              )}

              {/* </button> */}
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <Play24Regular
                  onClick={insertDiagramAsImage}
                  style={{ color: "white", cursor: "pointer", marginRight: "9px" }}
                />
                <Apps24Regular
                  onClick={() => setGalleryModal((prev) => !prev)}
                  style={{ color: "white", cursor: "pointer", marginRight: "9px" }}
                />
                <SaveArrowRight24Regular
                  onClick={() => handleSaveDiagram()}
                  style={{ color: "white", cursor: "pointer", marginRight: "9px" }}
                />
                <SaveModal
                  isOpen={saveModalOpen}
                  onClose={() => setSaveModalOpen(false)}
                  onSaveAsPic={handleSaveAsPic}
                  onSaveAsCode={handleSaveAsCode}
                  saveAsSvgFile={handleSaveAsPicSvg}
                />
              </div>

              <Panel
                isOpen={showNewPage}
                type={PanelType.custom}
                onDismiss={() => setShowNewPage(false)}
                // headerText="Import:"
                className="custom-panel"
                style={{
                  overflow: "hidden",
                  paddingLeft: "0 !important",
                  paddingRight: "4px !important",
                  // paddingLeft: "11 !important",
                  marginTop: "0 !important",
                  paddingBottom: "0 !important",
                }}
                hasCloseButton={false}
              >
                <div
                  style={{
                    backgroundColor,
                    padding: "2px",
                    width: "100% !important",
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: "0px !important",
                    paddingRight: "4px !important",
                    paddingBotto: 0,
                    top: "0 !important",
                    marginTop: "0 !important",
                  }}
                >
                  <Box sx={{ width: "100% !important" }}>
                    <Box sx={{ borderColor: "divider" }}>
                      <Tabs value={tabBarValue} onChange={tabBarHandleChange} aria-label="basic tabs example">
                        <Tab
                          label={
                            <div style={{ display: "flex", alignItems: "center", color: "white" }}>
                              <Icon
                                iconName="GlobalNavButton"
                                style={{ fontSize: "18px", color: "white", cursor: "pointer", marginRight: "6px" }}
                              />
                              Mermaid
                            </div>
                          }
                          onClick={handleCloseHamburgerClick}
                        />
                        <Tab label="Github" style={{ color: "white" }} />
                        <Tab label="OneDrive" style={{ color: "white" }} />
                      </Tabs>
                    </Box>
                  </Box>
                </div>
                <CustomTabPanel value={tabBarValue} index={0}></CustomTabPanel>
                <CustomTabPanel value={tabBarValue} index={1}>
                  <div style={{ display: "flex", flexDirection: "column", marginInline: 14, gap: 12 }}>
                    <Text style={{ fontSize: 19, fontFamily: "Montserrat" }}>My GitHub Diagrams</Text>
                    <Text style={{ fontSize: 13, fontFamily: "Montserrat" }}>Choose a Diagram that you have saved</Text>
                  </div>
                  {userData ? (
                    <>
                      {showMessageBarGithubGist && (
                        <MessageBar
                          messageBarType={MessageBarType.success}
                          isMultiline={false}
                          onDismiss={handleDrawerClose}
                          dismissButtonAriaLabel="Close"
                        >
                          Data retrieved successfully.
                        </MessageBar>
                      )}
                      {fileDetails.length > 0 ? (
                        <Grid container spacing={0} style={{ scrollbarWidth: 0 }} overflow={"hidden"}>
                          {fileDetails.map((folder) => (
                            <Grid item xs={7} md={5} key={folder.name}>
                              <List style={{ cursor: "pointer" }}>
                                <ListItem onClick={() => handleClickOpen(folder)}>
                                  <ListItemIcon>
                                    <FolderIcon style={{ color: "#FFDA6A" }} />
                                  </ListItemIcon>
                                  <ListItemText primary={folder.fileName} />
                                </ListItem>
                              </List>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 10,
                            alignItems: "center",
                            marginTop: 24,
                          }}
                        >
                          <FolderOffIcon
                            style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                            sx={{ fontSize: 33 }}
                          />
                          <Text style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500" }}>
                            No saved files.
                          </Text>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 10,
                        alignItems: "center",
                        marginTop: 24,
                      }}
                      onClick={handleAuthCallImport}
                    >
                      <NoAccountsIcon
                        style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                        sx={{ fontSize: 33 }}
                      />
                      <Text style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500" }}>
                        Please log in to Github to access your files.
                      </Text>
                    </div>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={tabBarValue} index={2}>
                  <div style={{ display: "flex", flexDirection: "column", marginInline: 14, gap: 12 }}>
                    <Text style={{ fontSize: 19, fontFamily: "Montserrat" }}>My OneDrive Diagrams</Text>
                    <Text style={{ fontSize: 13, fontFamily: "Montserrat" }}>Choose a Diagram that you have saved</Text>
                  </div>

                  {OneDriveLocalData ? (
                    <>
                      {showMessageBarGithubGist && (
                        <MessageBar
                          messageBarType={MessageBarType.success}
                          isMultiline={false}
                          onDismiss={handleDrawerClose}
                          dismissButtonAriaLabel="Close"
                        >
                          Data retrieved successfully.
                        </MessageBar>
                      )}
                      {oneDriveFiles && oneDriveFiles.length > 0 ? (
                        <Grid container spacing={0} style={{ scrollbarWidth: 0 }} overflow={"hidden"}>
                          {oneDriveFiles.map((folder) => {
                            console.log("folder ==>", folder);
                            return (
                              <Grid item xs={7} md={5} key={folder.name}>
                                <List style={{ cursor: "pointer" }}>
                                  <ListItem onClick={() => handleOpenOneDriveOpenImportCode(folder?.id)}>
                                    <ListItemIcon>
                                      <FolderIcon style={{ color: "#FFDA6A" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={folder.name} />
                                  </ListItem>
                                </List>
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : error ? (
                        <Stack
                          horizontalAlign="center"
                          verticalAlign="center"
                          verticalFill
                          gap={20}
                          style={{ padding: "20px" }}
                        >
                          <Image
                            src={OneDrive}
                            style={{ width: "55px", height: "55px", borderRadius: "26px", marginRight: "10px" }}
                          />

                          <Text variant="mediumPlus" style={{ fontFamily: "Montserrat" }}>
                            Session Expired
                          </Text>
                          <Text variant="medium" style={{ fontFamily: "Montserrat" }}>
                            If your Azure token has expired, you'll need to log out and then log in again
                          </Text>

                          <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                            <PrimaryButton
                              onClick={() => logout()}
                              text="Logout"
                              style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
                            />
                            {/* <PrimaryButton
                              onClick={() => setShowPopup1(false)}
                              text="Cancel"
                              style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
                            /> */}
                          </div>
                        </Stack>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: 10,
                            alignItems: "center",
                            marginTop: 24,
                          }}
                        >
                          <FolderOffIcon
                            style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                            sx={{ fontSize: 33 }}
                          />
                          <Text style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500" }}>
                            No saved files.
                          </Text>
                        </div>
                      )}
                    </>
                  ) : (
                    //  OneDrive Login Condition
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 10,
                        alignItems: "center",
                        marginTop: 24,
                      }}
                      onClick={handleAuthCallImport}
                    >
                      <NoAccountsIcon
                        style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                        sx={{ fontSize: 33 }}
                      />
                      <Text style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500" }}>
                        Please log in to OneDrive to access your files.
                      </Text>
                    </div>
                  )}
                </CustomTabPanel>
              </Panel>
              <Panel
                isOpen={showNewExport}
                type={PanelType.custom}
                onDismiss={() => setShowNewExport(false)}
                // headerText="Import:"
                className="custom-panel"
                style={{
                  overflow: "hidden",
                  paddingLeft: "0 !important",
                  paddingRight: "4px !important",
                  // paddingLeft: "11 !important",
                  marginTop: "0 !important",
                  paddingBottom: "0 !important",
                }}
                hasCloseButton={false}
              >
                <div
                  style={{
                    backgroundColor,
                    padding: "2px",
                    width: "100% !important",
                    display: "flex",
                    justifyContent: "center",
                    paddingLeft: "0px !important",
                    paddingRight: "4px !important",
                    paddingBotto: 0,
                    top: "0 !important",
                    marginTop: "0 !important",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderColor: "divider" }}>
                      <Tabs value={tabBarValue} onChange={tabBarHandleChange} aria-label="basic tabs example">
                        <Tab
                          label={
                            <div style={{ display: "flex", alignItems: "center", color: "white" }}>
                              <Icon
                                iconName="GlobalNavButton"
                                style={{ fontSize: "18px", color: "white", cursor: "pointer", marginRight: "6px" }}
                              />
                              Mermaid
                            </div>
                          }
                          onClick={handleCloseHamburgerClick}
                        />
                        <Tab label="Github" style={{ color: "white" }} />
                        <Tab label="OneDrive" style={{ color: "white" }} />
                      </Tabs>
                    </Box>
                  </Box>
                </div>

                <CustomTabPanel value={tabBarValue} index={1}>
                  <div style={{ display: "flex", flexDirection: "column", marginInline: 14, gap: 12 }}>
                    <Text style={{ fontSize: 19, fontFamily: "Montserrat" }}>My GitHub Diagrams</Text>
                    <Text style={{ fontSize: 13, fontFamily: "Montserrat" }}>
                      Save the SVG code for this mermaid on GitHub
                    </Text>
                  </div>
                  {userData ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 12,
                          flex: 1,
                          marginTop: 12,
                        }}
                      >
                        <PrimaryButton
                          onClick={addCodeGithubGist}
                          text="Export as Mermaid / Graphviz"
                          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
                        />
                        <PrimaryButton
                          onClick={saveSVGToGitHubGist}
                          text="Export as SVG"
                          style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px", width: "65%" }}
                        />
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 10,
                        alignItems: "center",
                        marginTop: 24,
                      }}
                    >
                      <NoAccountsIcon
                        style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                        sx={{ fontSize: 33 }}
                        onClick={handleAuthCallImport}
                      />
                      <Text style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500" }}>
                        Please log in to Github to Export your files.
                      </Text>
                    </div>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={tabBarValue} index={2}>
                  <div style={{ display: "flex", flexDirection: "column", marginInline: 14, gap: 12 }}>
                    <Text style={{ fontSize: 19, fontFamily: "Montserrat" }}>My OneDrive Diagrams</Text>
                    <Text style={{ fontSize: 13, fontFamily: "Montserrat" }}>
                      Save the SVG code for this mermaid on OneDrive
                    </Text>
                  </div>
                  <Modal
                    isOpen={showPopup1}
                    onDismiss={togglePopup1}
                    isBlocking={false}
                    containerClassName="modal-container custom-modal"
                    styles={{
                      main: {
                        borderRadius: "15px",
                      },
                    }}
                  >
                    {FileNameModal}
                  </Modal>
                  <Modal
                    isOpen={showPopup2}
                    onDismiss={togglePopup2}
                    isBlocking={false}
                    containerClassName="modal-container custom-modal"
                    styles={{
                      main: {
                        borderRadius: "15px",
                      },
                    }}
                  >
                    {FileNameSvgModal}
                  </Modal>
                  {/* {error && (
                    <Modal
                      isOpen={showPopup3}
                      onDismiss={togglePopup3}
                      isBlocking={false}
                      containerClassName="modal-container custom-modal"
                      styles={{
                        main: {
                          borderRadius: "15px",
                        },
                      }}
                    >
                      {SessionExpire}
                    </Modal>
                  )} */}

                  {OneDriveLocalData ? (
                    <>
                      {/*................. Get FileName By user .............................*/}
                      {error ? (
                        <Stack
                          horizontalAlign="center"
                          verticalAlign="center"
                          verticalFill
                          gap={20}
                          style={{ padding: "20px" }}
                        >
                          <Image
                            src={OneDrive}
                            style={{ width: "55px", height: "55px", borderRadius: "26px", marginRight: "10px" }}
                          />

                          <Text variant="mediumPlus" style={{ fontFamily: "Montserrat" }}>
                            Session Expired
                          </Text>
                          <Text variant="medium" style={{ fontFamily: "Montserrat" }}>
                            If your Azure token has expired, you'll need to log out and then log in again
                          </Text>

                          <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
                            <PrimaryButton
                              onClick={() => logout()}
                              text="Logout"
                              style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
                            />
                          </div>
                        </Stack>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 12,
                            flex: 1,
                            marginTop: 12,
                          }}
                        >
                          <PrimaryButton
                            onClick={togglePopup1}
                            text="Export as Mermaid / Graphviz"
                            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px" }}
                          />
                          <PrimaryButton
                            onClick={togglePopup2}
                            text="Export as SVG"
                            style={{ backgroundColor: "#B6472B", border: "none", borderRadius: "10px", width: "65%" }}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 10,
                        alignItems: "center",
                        marginTop: 24,
                      }}
                      onClick={handleAuthCallExport}
                    >
                      <NoAccountsIcon
                        style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                        sx={{ fontSize: 33 }}
                      />
                      <Text style={{ fontSize: 13, fontFamily: "Montserrat", color: "#b5462a", fontWeight: "500" }}>
                        Please log in to OneDrive to Export your files.
                      </Text>
                    </div>
                  )}
                </CustomTabPanel>
              </Panel>
            </div>
            <button class="button">
              <Stack.Item>
                {onDriveData || userData ? (
                  onDriveData?.pic || userData?.avatar_url ? (
                    <img
                      src={onDriveData?.pic || userData?.avatar_url}
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "20px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      onClick={togglePopup}
                    />
                  ) : (
                    <Avatar sx={{ width: 24, height: 24 }} onClick={togglePopup}>
                      {userData?.name?.charAt(0).toUpperCase() || onDriveData?.displayName?.charAt(0).toUpperCase()}
                    </Avatar>
                  )
                ) : (
                  <Icon
                    iconName="Contact"
                    style={{ fontSize: "18px", color: "white", cursor: "pointer", marginRight: "10px" }}
                    onClick={togglePopup}
                  />
                )}
              </Stack.Item>
            </button>
          </div>
        </Stack>
      </div>
      <Modal
        isOpen={showPopup4}
        onDismiss={togglePopup4}
        isBlocking={false}
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
            width: "80%",
            height: "80%",
            maxWidth: "none",
          },
        }}
      >
        {templateModal}
      </Modal>
      <Modal
        isOpen={showGithubPopup}
        onDismiss={toggleGithub}
        isBlocking={false}
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
          },
        }}
      >
        {GithubContent}
      </Modal>
      <Modal
        isOpen={showOneDrivePopup}
        onDismiss={toggleOneDrive}
        isBlocking={false}
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
          },
        }}
      >
        {OneDriveContent}
      </Modal>
      {/* ......................No login Account........................... */}
      <Modal
        isOpen={noLoginState}
        onDismiss={noLogintogglePopup}
        isBlocking={false}
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
          },
        }}
      >
        {NoAccountModal}
      </Modal>
      <Modal
        isOpen={isModalOpen}
        onDismiss={handleCloseModal}
        isBlocking={false}
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
            minHeight: 45,
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <Text style={{ textAlign: "center" }} variant="xLarge">
              Export:
            </Text>
            <DefaultButton
              style={{ border: "none", backgroundColor: "transparent" }}
              iconProps={{ iconName: "Cancel" }}
              onClick={handleCloseModal}
            />
          </Stack>

          {userData || onDriveData ? (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              {/* .....................toast Message................. */}
              {showMessageBar && (
                <MessageBar
                  messageBarType={MessageBarType.error} // You can use different message types like error, info, warning, success
                  isMultiline={false}
                  onDismiss={() => setShowMessageBar(false)}
                  dismissButtonAriaLabel="Close"
                >
                  Log in to OneDrive before sharing data
                </MessageBar>
              )}
              {showMessageBarGithub && (
                <MessageBar
                  messageBarType={MessageBarType.error} // You can use different message types like error, info, warning, success
                  isMultiline={false}
                  onDismiss={() => setShowMessageBarGithub(false)}
                  dismissButtonAriaLabel="Close"
                >
                  Log in to Github before sharing data
                </MessageBar>
              )}
              <p style={{ fontSize: 18 }}></p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: 12,
                  alignSelf: "center",
                }}
              >
                <PrimaryButton
                  onClick={handleAlertGithub}
                  text="Github"
                  style={{ backgroundColor: "#000", border: "none", borderRadius: "10px", height: 40 }}
                >
                  <img src={github} alt="GitHub Icon" style={iconStyle} />
                </PrimaryButton>
                <PrimaryButton
                  onClick={() => handleAlert(userData)}
                  text="OneDrive"
                  style={{ backgroundColor: "#2b88d8", border: "none", borderRadius: "10px", height: 40 }}
                >
                  <img src={OneDrive} alt="OneDrive Icon" style={iconStyle} />
                </PrimaryButton>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
              <NoAccountsIcon
                style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
                sx={{ fontSize: 33 }}
              />
              <Text
                onClick={handleLoginModal}
                style={{
                  color: "red",
                  cursor: "pointer",
                }}
              >
                You need to log in to access this feature.
              </Text>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Header;

function CustomTabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <div>
          <p>{children}</p>
        </div>
      )}
    </div>
  );
}
