import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";

/* global document, Office, module, require */

// Register the Office.onReady event
Office.onReady(function (info) {
  if (
    info.host === Office.HostType.Word ||
    info.host === Office.HostType.Excel ||
    info.host === Office.HostType.POWERPOINT
  ) {
    // The Office.js library is initialized, and it's safe to use the Office API.
    render(App, true);
  } else {
    render(App, false);
  }
});

const render = (Component, isOfficeInitialized) => {
  initializeIcons();
  ReactDOM.render(
    <AppContainer>
      <App isOfficeInitialized={isOfficeInitialized} />
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Initial render showing a progress bar */
// render(App);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
