import * as React from "react";
import PropTypes from "prop-types";
import Extentsion from "./Extension/Extension";
import { ThemeProvider } from "./themeContext/ThemeContext";
import AppProviders from "../../context";
import { ClerkProvider } from "@clerk/clerk-react";

// Main App component that integrates various providers and the main Extension component
export default function App({ isOfficeInitialized }) {
  return (
    <AppProviders>
      {/* ClerkProvider for authentication, using the provided publishable key */}
      <ClerkProvider publishableKey={`pk_test_ZHluYW1pYy1qYWNrYWwtMTYuY2xlcmsuYWNjb3VudHMuZGV2JA`}>
        {/* ThemeProvider for theme context management */}
        <ThemeProvider>
          {/* {isOfficeInitialized ? <Extentsion /> : <span>Add-in only works with powerpoint</span>} */}
          <Extentsion />
        </ThemeProvider>
      </ClerkProvider>
    </AppProviders>
  );
}

// Define the prop types for the App component
App.propTypes = {
  isOfficeInitialized: PropTypes.bool.isRequired,
};
