import React, { useState, useEffect, useContext } from "react";
import TemplateScreenModal from "../components/templateScreenModal/TemplateScreenModal";
import { Modal } from "@fluentui/react";
import { Grid, Card, CardActionArea, CardMedia, CardContent, Typography, IconButton } from "@mui/material";
import { useTheme } from "../components/themeContext/ThemeContext";
import { Favorite } from "@mui/icons-material";
import { Image, Text, PrimaryButton } from "office-ui-fabric-react";
import Viz from "viz.js";
import mermaid from "mermaid";
import { Module, render } from "viz.js/full.render.js";
import { GlobalContext } from "../../context/global";

const CardModal = ({ modalData, handleCardCloseModal, modalCard, directMermaidDaigram, directGraphvizDaigram }) => {
  const { currentTheme } = useTheme();

  const { setGalleryModal, setInputValue, setMarkdown, setGraphvizCode } = useContext(GlobalContext);

  // Determine theme preferences
  const isDarkTheme = currentTheme === "vs-dark";
  const isHighContrastTheme = currentTheme === "hc-black";

  // Function to insert a diagram as an image into the document
  const insertDiagramAsImage = () => {
    if (directMermaidDaigram) {
      console.log("markdown code in condition ==>", directMermaidDaigram);
      // Handle Mermaid diagram
      const diagramImage = convertMermaidToImage(directMermaidDaigram);
    } else if (directGraphvizDaigram) {
      console.log("graphviz code ==>", directGraphvizDaigram);
      // Handle Graphviz diagram
      const viz = new Viz({ Module, render });
      viz
        .renderString(directGraphvizDaigram)
        .then((graphvizResult) => {
          handleDiagramInsertion(graphvizResult);
          // handleToggleChange()
        })
        .catch((graphvizError) => {
          console.error("Error rendering Graphviz code:", graphvizError);
        });
    } else {
      console.log("No diagram code found.");
    }
  };
  // Mapping icons from one format to another
  const iconMapping = {
    "fa:fa-car": "**",
    "::icon(fa fa-book)": "&**",
  };
  // Function to parse Mermaid code and replace icons
  function parseMermaidCode(mermaidCode) {
    for (const faIcon in iconMapping) {
      const pptIcon = iconMapping[faIcon];
      mermaidCode = mermaidCode.replace(new RegExp(faIcon, "g"), `${pptIcon}`);
    }
    return mermaidCode;
  }

  // Function to convert Mermaid code to an image and insert it
  const convertMermaidToImage = (mermaidCode) => {
    try {
      console.log("mermaid code ==>", mermaidCode);
      // Convert the Mermaid code to an SVG representation
      mermaidCode = parseMermaidCode(mermaidCode);

      mermaid.render("mermaid", mermaidCode).then((svgImage) => {
        // Access the SVG string from the 'svg' property
        const svgString = svgImage.svg;

        console.log("mermaid chart ==>", svgString);

        // Wrap the SVG string in a <pre> tag
        const svgStringWithPreTag = `<pre class="mermaid">${svgString}</pre>`;
        // ... Rest of your code
        Office.context.document.setSelectedDataAsync(
          svgString,
          {
            coercionType: Office.CoercionType.XmlSvg,
            imageLeft: 50,
            imageTop: 50,
            imageWidth: 400,
          },

          function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.error(asyncResult.error.message);
              localStorage.removeItem("mermaidSvg");
              localStorage.removeItem("markdown");
            } else {
              localStorage.removeItem("mermaidSvg");
              localStorage.removeItem("markdown");
            }
          }
        );
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  // Function to handle insertion of Graphviz diagrams
  const handleDiagramInsertion = (graphvizResult) => {
    // Handle Graphviz SVG
    // Parse the XML result to get the SVG content
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(graphvizResult, "text/xml");
    const svgContent = xmlDoc.querySelector("svg").outerHTML;
    console.log("svg Content ===>", svgContent);
    if (Office.context.host === Office.HostType.PowerPoint) {
      // Check if PowerPoint is initialized
      if (Office.context.document) {
        Office.context.document.setSelectedDataAsync(
          svgContent,
          {
            coercionType: Office.CoercionType.XmlSvg,
            imageLeft: 50,
            imageTop: 50,
            imageWidth: 400,
          },
          (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.error("Error setting selected data:", asyncResult.error.message);
            }
          }
        );
      } else {
        console.log("PowerPoint is not yet initialized.");
      }
    } else {
      console.log("This code is intended for PowerPoint add-ins.");
    }
  };

  // Function to fetch diagram data by ID and insert it into the input field
  const fetchDataAndInsertInput = async () => {
    try {
      if (modalData?.diagram_type === "mermaid") {
        const response = await fetch(`https://mrk.elenta.co/visual/mermaid/${modalData?.id}/`);
        const rawData = await response.text();

        setInputValue(rawData);
        setMarkdown(rawData);
        handleCardCloseModal();
        setGalleryModal(false);
      } else {
        const response = await fetch(`https://mrk.elenta.co/visual/graphviz/${modalData?.id}/`);
        const rawData = await response.text();
        const StripData = rawData[0] == "" ? rawData.slice(1) : rawData;
        setInputValue(StripData);
        setGraphvizCode(rawData);
        handleCardCloseModal();
        setGalleryModal(false);
        console.log("Diagram by ID (Raw Data):", rawData);
      }
    } catch (error) {
      console.error("Error fetching diagram by ID:", error);
    }
  };

  return (
    <Modal
      isOpen={modalCard}
      onDismiss={handleCardCloseModal}
      isBlocking={false}
      containerClassName="modal-container custom-modal"
      styles={{
        main: {
          borderRadius: "20px",
          alignSelf: "center",
        },
      }}
    >
      <div style={{ padding: "20px" }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
          gap={5}
          marginTop={3}
          style={{ display: "flex", justifyContent: "center", marginBottom: 12, height: "80%", cursor: "pointer" }}
        >
          <div style={{ maxWidth: 250 }}>
            <CardMedia height="140">
              <Image src={modalData?.image} style={{ width: "100%", height: 200 }} />
            </CardMedia>
            <CardContent style={{ padding: 4 }}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography gutterBottom variant="h5" component="div" fontFamily="Montserrat">
                  {modalData?.title}
                </Typography>
              </div>
              <Typography variant="body2" color="text.secondary" fontFamily="Montserrat">
                {modalData?.description}
              </Typography>
            </CardContent>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignSelf: "center",
              gap: 4,
              width: "100%",
              marginLeft: 13,
            }}
          >
            <PrimaryButton
              onClick={insertDiagramAsImage}
              text="Insert Diagram"
              style={{
                backgroundColor: "#B6472B",
                border: "none",
                borderRadius: "10px",
                top: 9,
                height: 40,
                width: "50%",
              }}
            />
            <PrimaryButton
              onClick={fetchDataAndInsertInput}
              text="Insert Code"
              style={{
                backgroundColor: "#B6472B",
                border: "none",
                borderRadius: "10px",
                top: 9,
                height: 40,
                width: "50%",
              }}
            />
          </div>
        </Grid>
      </div>
    </Modal>
  );
};
export default CardModal;
